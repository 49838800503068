import React, { useState, useCallback, useEffect } from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText, PrismicLink } from '@prismicio/react'
import { Container } from '../components/Container'
import {
  GoogleMap,
  LoadScript,
  InfoWindow,
  KmlLayer,
  Marker
} from "@react-google-maps/api";

const lib = ["places"];
const idG = ["614f2b924c037cf7"];
const key = "AIzaSyBGKJ2tOqSdO5XKsCcgdOU1yHhvTi8TJPs"; // PUT GMAP API KEY HERE
const defaultLocation = { lat: 60.219386, lng: -101.645961 };

export const Map = ({ slice }) => {
  const [map, setMap] = useState(null);  
  const onLoad = useCallback((map) => setMap(map), []);


  return (
    <section className="Map">
      <a className="anchor" id={slice.primary.anchor_id}></a>
      <div className="map-wrap">
        
        <LoadScript googleMapsApiKey={key} libraries={lib} mapIds={idG}>
          <GoogleMap
            center={defaultLocation}
            zoom={3.5}
            onLoad={onLoad}
            options={{ mapId: "614f2b924c037cf7" }}
            mapContainerStyle={{ height: "600px"}}
            disableDefaultUI={true}
            fullscreenControl={false}
          >

            {slice.primary.marker_link?.url && 
              <KmlLayer
                url={slice.primary.marker_link?.url}
                options={{ preserveViewport: true }}
              />
            }
          </GoogleMap>
        </LoadScript>
        <div className="info-box">
          <PrismicRichText field={slice.primary.info_box?.richText}/>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyMap on PrismicHomepageDataBodyMap {
    id
    primary {
      anchor_id
      marker_link {
        url
      }
      info_box {
        richText 
      }
    }
  }
`
