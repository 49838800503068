import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText } from '@prismicio/react'
import { Container } from '../components/Container'

export const Title = ({ slice }) => {
  return (
    <div className="Title">
      <div className="title-wrap" style={{textAlign: `${slice.primary.text_align || "left"}`}}>
        <Container>
          <PrismicRichText field={slice.primary.display_title?.richText}/>
        </Container>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment PageDataBodyTitle on PrismicPageDataBodyTitle {
    primary {
      display_title {
        richText
      }
      text_align
    }
  }
`
