import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText, PrismicLink } from '@prismicio/react'
import { Container } from '../components/Container'

export const Stories = ({ slice }) => {
  const bg_style = {
    background: "url(" + slice.primary.background_image?.url + ")",
    backgroundColor: slice.primary.background_colour,
    backgroundPosition: "0% 100%",
    backgroundSize: "cover"
  };
  return (
    <div className="Stories">
      <section className="Stories-Hero" style={bg_style}>
        <Container>
          <div className="copy">
            <h1>{slice.primary.title}</h1>
            <p>{slice.primary.subtitle}</p>
          </div>
        </Container>
      </section>
      <section className="Stories-Impact">
        <Container>
          <div className="wrap">
            {slice.items.map((item,index) => ( 
              <div className="impact-listing" key={index}>
                <div className="video">
                  <div className="iframe-box">
                    <div dangerouslySetInnerHTML={{ __html: item.video?.html }} />
                  </div>
                </div>
                <div className="copy">
                  <h3>{item.name}</h3>
                  <p><span>{item.organization}</span></p>
                  <p>{item.info}</p>
                  <div className="socials">
                    <PrismicLink href={item.instagram?.url} className="social-icon">
                      <StaticImage src="../images/instagram.png" alt="instagram" width={24}/>
                    </PrismicLink>
                    <PrismicLink href={item.facebook?.url} className="social-icon">
                      <StaticImage src="../images/facebook.png" alt="facebook" width={24}/>
                    </PrismicLink>
                    <PrismicLink href={item.twitter?.url} className="social-icon">
                      <StaticImage src="../images/twitter.png" alt="twitter" width={24}/>
                    </PrismicLink>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Container>
      </section>
    </div>
  )
}

export const query = graphql`
  fragment PageDataBodyStories on PrismicPageDataBodyStories {
    id
    primary {
      title 
      subtitle
      background_image {
        url
      }
      background_colour
    }
    items {
      video {
        embed_url
        html
      }
      name 
      organization
      info
      instagram {
        url
      }
      facebook {
        url
      }
      twitter {
        url
      }
    }
  }
`