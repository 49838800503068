import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText, PrismicLink } from '@prismicio/react'
import { Container } from '../components/Container'
export const Partners = ({ slice }) => {
  const bg_style = {
    background: "url(" + slice.primary.background_image?.url + ")",
    backgroundColor: slice.primary.background_colour
  };
  return (
    <section className="Partners" style={bg_style}>
      <a className="anchor" id={slice.primary.anchor_id}></a>
      <Container>
        <div className="center-wrap">
          <h2>{slice.primary.title}</h2>
          <p>{slice.primary.info}</p>
          <div className="center-row">
            {slice.items.map((item,index) => (  
              <PrismicLink key={index} href={item.partner_link?.url}>
                <GatsbyImage image={item.partner_image?.gatsbyImageData} alt={item.partner_image?.alt || ""} />
              </PrismicLink>
            ))}

          </div>
        </div>
      </Container>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyPartners on PrismicHomepageDataBodyPartners {
    id
    primary {
      anchor_id
      background_image {
        url
      }
      background_colour
      title 
      info
    }
    items {
      partner_image {
        gatsbyImageData
        alt
      }
      partner_link {
        url
      }
    }
  }
`