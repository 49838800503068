import  React, { useState, useCallback, useEffect } from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText, PrismicLink } from '@prismicio/react'
import { Container } from '../components/Container'

export const Impact = ({ slice }) => {
  const bg_style = {
    background: "url(" + slice.primary.background_image?.url + ")",
    backgroundColor: slice.primary.background_colour
  };
  return (
    <section className="Impact" style={bg_style}>
      <a className="anchor" id={slice.primary.anchor_id}></a>
      <Container>
        <div className="center-wrap">
          <h2>{slice.primary.title}</h2>
          <div className="card-row">
            {slice.items.map((item,index) => ( 
              <div className="card" key={`impact-${index}`}>
                <h3>{item.stat}</h3>
                <p>{item.stat_info}</p>
              </div>
            ))}
          </div>
        </div>
      </Container>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyImpact on PrismicHomepageDataBodyImpact {
    id
    primary {
      background_image {
        url
      }
      background_colour
      anchor_id
      title
    }
    items {
      stat
      stat_info
    }
  }
`