import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText, PrismicLink } from '@prismicio/react'
import { Container } from '../components/Container'
export const Applications = ({ slice }) => {
  const bg_style = {
    background: "url(" + slice.primary.background_image?.url + ")",
    backgroundColor: slice.primary.background_colour
  };
  return (
    <section className="Applications" style={bg_style}>
      <a className="anchor" id={slice.primary.anchor_id}></a>
      <Container>
        <div className="center-wrap">
          <h2>{slice.primary.title}</h2>
          <PrismicRichText field={slice.primary.copy?.richText}/>
          { slice.primary.application_status ? 
            <PrismicRichText field={slice.primary.open_richtext?.richText}/> :
            <PrismicRichText field={slice.primary.closed_richtext?.richText}/>
          }
        </div>
      </Container>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyApplications on PrismicHomepageDataBodyApplications {
    id
    primary {
      anchor_id
      background_image {
        url
      }
      background_colour
      title
      copy {
        richText
      }
      application_status
      closed_richtext {
        richText 
      }
      open_richtext {
        richText
      }
    }
  }
`