import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText, PrismicLink } from '@prismicio/react'
import { Container } from '../components/Container'
export const Funding = ({ slice }) => {
  const bg_style = {
    background: "url(" + slice.primary.background_image?.url + ")",
    backgroundColor: slice.primary.background_colour
  };
  return (
    <section className="Funding" style={bg_style} >
      <a className="anchor" id={slice.primary.anchor_id}></a>
      <Container>
        <div className="center-wrap">
          <h2>{slice.primary.title}</h2>
          <div className="card-row">
            {slice.items.map((item,index) => (
              <div className="card" key={index}>
                <div className="image-box">
                  
                  <GatsbyImage image={item.image?.gatsbyImageData} alt={item.image?.alt || ""}/>
                </div>
                <h3>{item.card_title}</h3>
                <p>{item.card_copy}</p>
              </div>
            ))}
          </div>
        </div>
      </Container>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyFunding on PrismicHomepageDataBodyFunding {
    id
    primary {
      anchor_id
      background_image {
        url
      }
      background_colour
      title
    }
    items {
      image {
        url
        gatsbyImageData
        alt
      }
      card_title
      card_copy
    }
  }
`