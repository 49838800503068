import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText, PrismicLink } from '@prismicio/react'
import { Container } from '../components/Container'
import ExtLink from '../images/external-link.svg'


export const InstagramFeed = ({ slice }) => {
  const insta = slice.items.slice().reverse(); 
  const bg_style = {
    background: "url(" + slice.primary.background_image?.url + ")",
    backgroundColor: slice.primary.background_colour
  };

  function truncate(item) {
    var length = 60;
    return item.length> length ? item.slice(0,length)+"..." : item;
  }
  return (
    <>
    <a className="anchor" id={slice.primary.anchor_id}></a>
    <section className="InstagramFeed" style={bg_style}>
      <Container>
        <div className="center-wrap">
          <div className="title"><h2>{slice.primary.title}</h2></div>
          <div className="card-row">
            {insta.map((item,index) => (
              <div key={`insta-post-2:${index}`} className="post">
                <PrismicLink href={item.instagram_post?.url} className="insta-image">
                  {item.post_image?.gatsbyImageData &&
                    <GatsbyImage
                      image={item.post_image?.gatsbyImageData}
                      alt={item.post_image?.alt || ""}
                    />
                  }
                </PrismicLink>
                <div className="insta-copy"><PrismicRichText field={item.post_description?.richText} /></div>
              </div>
            ))}
          </div>
          <div className="btn-box">
            <PrismicLink href={slice.primary.button_link?.url} className="gold-btn">
              {slice.primary.button_label}<img src={ExtLink} alt=""/>
            </PrismicLink>
          </div>
        </div>
      </Container>
    </section>
    </>
  )
}

export const query = graphql`
  fragment HomepageDataBodyInstagramFeed on PrismicHomepageDataBodyInstagramFeed {
    id
    primary {
      background_image {
        url
      }
      background_colour
      anchor_id
      title 
      num_posts
      button_label
      button_link {
        url 
      }
    }
    items {
      post_image {
        gatsbyImageData
        alt
      }
      instagram_post {
        url
      }
      post_description {
        richText 
      }
    }
  }
`