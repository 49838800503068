import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText, PrismicLink } from '@prismicio/react'
import { Container } from '../components/Container'


export const HomepageHero = ({ slice }) => {
  const bg_style = {
    background: "url(" + slice.primary.background_image?.url + ")",
    backgroundColor: slice.primary.background_colour
  };
  return (
    <section className="HomepageHero" style={bg_style}>
      <Container>
        <div className="flex-wrap">
          <div className="copy">
            <h1>{slice.primary.title}</h1>
            <p><span className="subtitle">{slice.primary.subtitle}</span></p>
            <div className="hero-dec-btn">
              <a href={slice.primary.button_link_id} >{slice.primary.button_label}</a>
            </div>
            <div className="description">
              <PrismicRichText field={slice.primary.description?.richText} />
            </div>
          </div>
          <div className="image">
            <GatsbyImage
              image={slice.primary.image?.gatsbyImageData}
              alt={slice.primary.image?.alt || ""}
            />
          </div>
        </div>
      </Container>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyHomepageHero on PrismicHomepageDataBodyHomepageHero {
    id
    primary {
      background_image {
        url
      }
      background_colour
      title
      subtitle
      button_label
      button_link_id
      description{
        richText
      }
      image {
        gatsbyImageData(width: 288)
        alt
      }
    }
  }
`