import React, { useRef, useEffect, useState } from 'react'
import { Link, graphql } from 'gatsby'
import { PrismicLink, PrismicText } from '@prismicio/react'
import { StaticImage,GatsbyImage } from 'gatsby-plugin-image'
import { LanguageSwitcher } from './LanguageSwitcher'
import { Container } from './Container'

const getDimensions = ele => {
  const { height } = ele.getBoundingClientRect();
  const offsetTop = ele.offsetTop;
  const offsetBottom = offsetTop + height;

  return {
    height,
    offsetTop,
    offsetBottom,
  };
};

export const TopMenu = ({ topMenu, activeDocMeta }) => {
  const lang = activeDocMeta.lang
  function idify(id) {
    return id.charAt(0) == "#" ? id : "#"+id
  }
  function anchored(id){ 
    return id ? "#"+id : "#header"
  }
  function selectHeight(items){
    const scrollTop = window.scrollY + 95;
    if (activeDocMeta.type != 'homepage') return "";
    if (scrollTop < document.querySelector(anchored(items[0].section_id)).offsetTop){
      return "";
    } 
    for (var i = 1; i < items.length; i++){
      var prevID = items[i-1].section_id;
      var curID = items[i].section_id;
      var curHeight = curID ? document.querySelector(anchored(curID)).offsetTop : 0;
      if (scrollTop < curHeight) { return prevID };
    }
    return curID;
  }
  const [visibleSection, setVisibleSection] = useState("");
  useEffect(() => {
    const handleScroll = () => {
      setVisibleSection(selectHeight(topMenu.menu_links.filter(link => link.section_id != null)))
    };
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [visibleSection]);

  const styles = {
    active: {
      color: "#F9A52C",
    },
    hidden: {
     textDecoration: "none"
    }
  }
  return (
    <header>
      <div className="menu">
        <Container>
          <ul className="menu-links">
            <li className="menu-logo">
              <PrismicLink href={lang=="en-ca" ? "/" : "/"+lang} className="menu-logo_link">
                <GatsbyImage 
                  image={topMenu.logo?.gatsbyImageData}
                  alt={topMenu.logo?.alt || ""}
                />
              </PrismicLink>
            </li>
            <li className="links">
            {topMenu.menu_links.map((item,index) => (
              <div key={index} >
                  <PrismicLink href={!item.link_type ? item.link?.url :lang=="en-ca" ? `/${idify(item.section_id)}` : 
                  `/${lang}/${idify(item.section_id)}`} 
                    style={visibleSection===item.section_id ? styles.active : styles.hidden}
                  >
                    {item.link_label}
                  </PrismicLink>                            
              </div>
            ))}
            </li>
          </ul>
        </Container> 
      </div>
    </header>
  )
}

export const query = graphql`
  fragment TopMenuFragment on PrismicTopMenu {
    _previewable
    type
    lang
    data {
      logo {
        gatsbyImageData(width: 240, height: 41, imgixParams: {q: 100})
        alt
      }
      menu_links {
        link_type
        link {
          url
        }
        section_id
        link_label
      }
    }
  }
`
