import * as React from 'react'
import { Link, graphql } from 'gatsby'
import { PrismicLink, PrismicText } from '@prismicio/react'
import { StaticImage, GatsbyImage } from 'gatsby-plugin-image'
import { Container } from './Container'

export const BottomMenu = ({ bottomMenu}) => {
  return (
    <footer>
      <Container>
        <ul className="menu-links">
          <li className="copyright"><p>{bottomMenu.copyright}</p></li>
          <li>
            <PrismicLink href={bottomMenu.email?.url} className="social-icon">
              <StaticImage src="../images/index.png" alt="email" width={24}/>
            </PrismicLink>
          </li>
          <li>
            <PrismicLink href={bottomMenu.instagram?.url} className="social-icon">
              <StaticImage src="../images/instagram.png" alt="instagram" width={24}/>
            </PrismicLink>
          </li>
          <li>
            <PrismicLink href={bottomMenu.youtube?.url} className="social-icon">
              <StaticImage src="../images/youtube.png" alt="youtube" width={24}/>
            </PrismicLink>
          </li>
          <li>
            <PrismicLink href={bottomMenu.facebook?.url} className="social-icon">
              <StaticImage src="../images/facebook.png" alt="facebook" width={24}/>
            </PrismicLink>
          </li>
        </ul>
      </Container>
    </footer>
  )
}

export const query = graphql`
  fragment BottomMenuFragment on PrismicBottomMenu {
    _previewable
    type
    lang
    data {
      copyright
      email {
        url
      }
      instagram {
        url
      }
      youtube {
        url
      }
      facebook {
        url
      }
    }
  }
`
