import { Applications } from './Applications'
import { Funding } from './Funding'
import { HomepageHero } from './HomepageHero'
import { Partners } from './Partners'
import { Requirements } from './Requirements'
import { Impact } from './Impact'
import { Map } from './Map'
import { InstagramFeed } from './InstagramFeed'

import { Stories } from './Stories'
import { FaqSection } from './FaqSection'
import { Title } from './Title'

export const components = {
  applications: Applications,
  funding: Funding,
  homepage_hero: HomepageHero,
  partners: Partners,
  requirements: Requirements,
  stories: Stories,
  
  impact: Impact,
  instagram_feed: InstagramFeed,
  faq_section: FaqSection,
  title: Title,
  map: Map,
}
