import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText } from '@prismicio/react'
import { Container } from '../components/Container'

export const FaqSection = ({ slice }) => {
  return (
    <section className="faq_section">
      <Container>
        <div className="flex_content">
          {slice.primary.header?.richText && 
            <div className="page_title">
              <PrismicRichText field={slice.primary.header.richText}/>
            </div>
          }
          {slice.items.map((galleryItem, index) => (
            <details key={`question:${index}`}>
              <summary>
                <PrismicRichText field={galleryItem.item_header.richText}/>
              </summary>
              <PrismicRichText field={galleryItem.item_text.richText}/>
            </details>
          ))}
        </div>
      </Container>
    </section>
  )
}

export const query = graphql`
  fragment PageDataBodyFaqSection on PrismicPageDataBodyFaqSection {
    primary {
      header {
        richText
      }
    }
    items{
      item_header{
        richText
      }
      item_text{
        richText
      }
    }
  }
`
